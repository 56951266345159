import { authInitialState, AuthState } from "../state/auth.state";
import { AuthActionType, AuthAction } from "../actions/auth.action";

export function authReducer(
  state = authInitialState,
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionType.LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      });
    }
    case AuthActionType.UPDATE_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    }

    case AuthActionType.UPDATE_USER_ROLE: {
      return Object.assign({}, state, {
        isAdmin: action.payload.isAdmin,
      });
    }

    case AuthActionType.REQUESTED_PASSWORD_RESET: {
      return Object.assign({}, state, { email: action.payload.email });
    }

    case AuthActionType.VERIFICATION_EMAIL: {
      return Object.assign({}, state, { email: action.payload.email });
    }

    case AuthActionType.LOGIN_FAILED: {
      return Object.assign({}, state, {
        user: null,
        isLoading: false,
        isLoggedIn: false,
      });
    }

    case AuthActionType.AUTH_ERROR: {
      return Object.assign({}, state, {
        error: action.payload,
      });
    }

    case AuthActionType.CLEARERROR: {
      return Object.assign({}, state, {
        error: null,
      });
    }

    case AuthActionType.LOGOUT_COMPLETED: {
      return Object.assign({}, state, {
        user: null,
        isLoading: false,
        isLoggedIn: false,
      });
    }

    default:
      return state;
  }
}
