import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MustMatch } from "../shared/_helpers/must-match.validator";
import { Store, select } from "@ngrx/store";
import { AppState } from "../store/reducers";
import * as actions from "../store/actions/auth.action";
import { ActivatedRoute } from "@angular/router";
import { getError } from "../store/selectors/auth.selectors";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: "dialog-content-tc",
  templateUrl: "dialog-content-tc.html",
  styleUrls: ["./sign-up.component.css"],
})
export class DialogContentTC {
  //  Terminos y condiciones
  constructor() {}
}

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit, OnDestroy {
  UserP: any = {
    username: "",
    email: "",
    displayName: "",
    photoURL: "#",
    password: "",
    passwordConfir: "",
  };

  error$: Observable<string | null>;

  registerForm: FormGroup;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}
  ngOnDestroy(): void {
    this.store.dispatch(new actions.ClearError());
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    this.registerForm = this.formBuilder.group(
      {
        username: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        tc: ["", Validators.required],
        password: ["", [Validators.required, Validators.minLength(6)]],
        passwordConfir: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "passwordConfir"),
      }
    );
    this.HandlingError();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentTC);

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      this.registerForm.patchValue({
        tc: result,
      });
    });
  }

  HandlingError() {
    this.error$ = this.store.select(getError);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid || !this.registerForm.value.tc) {
      Swal.fire({
        title: "warning",
        text: "Complete los campos del formulario",
        icon: "warning",
        showCloseButton: true,
      });
      return;
    }

    this.UserP.username = this.registerForm.value.username;
    this.UserP.email = this.registerForm.value.email;
    this.UserP.password = this.registerForm.value.password;

    this.UserP.displayName = this.registerForm.value.username;

    this.UserP.photoURL = null;
    //this.Signup(this.UserP);

    if (this.registerForm.valid) {
      const username = this.registerForm.value.username;
      const email = this.registerForm.value.email;
      const password = this.registerForm.value.password;
      const returnUrl = this.returnUrl;

      this.store.dispatch(
        new actions.RegisterRequested({ username, email, password, returnUrl })
      );
      this.resetError();
    }

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.UserP));
  }

  resetError(): void {
    setTimeout(() => {
      this.store.dispatch(new actions.ClearError());
    }, 8000);
  }
}
