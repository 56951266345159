export interface IUser {
  uid: string;
  displayName: string;
  photoURL?: string;
  loading?: boolean;
  error?: string;
  token?: string;
}

export class User {
  uid: string;
  displayName: string;
  email: string;
  providerId: string;
  photoUrl?: string;
  phoneNumber?: string;
  isNewUser?: boolean;
  isAnonymous?: boolean;
  isVerify?: boolean;
  isAdmin?: boolean;
  isOnline?: boolean;
  loading?: boolean;
  error?: string;
  token?: string;

  constructor() {
    (this.displayName = ""),
      (this.email = ""),
      (this.providerId = ""),
      (this.uid = ""),
      (this.error = ""),
      (this.phoneNumber = ""),
      (this.isAdmin = false),
      (this.isNewUser = false),
      (this.isAnonymous = false),
      (this.isVerify = false),
      (this.isOnline = false),
      (this.loading = false),
      (this.photoUrl = ""),
      (this.token = "");
  }
}

export class FirebaseErrors {
  static Parse(errorCode: string): string {
    let message: string;
    console.log(errorCode);
    switch (errorCode) {
      case "auth/popup-closed-by-user":
        message = "Popup de sesion cerrado por el usuario.";
        break;
      case "auth/wrong-password":
        message = "Credenciales de inicio de sesión no válidas.";
        break;
      case "auth/network-request-failed":
        message = "Compruebe su conexión a Internet";
        break;
      case "auth/too-many-requests":
        message =
          "Hemos detectado demasiadas solicitudes de su dispositivo. ¡Tómate un descanso, por favor!";
        break;
      case "auth/user-disabled":
        message =
          "Su cuenta ha sido deshabilitada o eliminada. Por favor, póngase en contacto con el administrador del sistema.";
        break;
      case "auth/requires-recent-login":
        message = "¡Por favor, inicie sesión nuevamente e intente nuevamente!";
        break;
      case "auth/email-already-in-use":
        message =
          "La dirección de correo electrónico ya está siendo utilizada por un usuario existente.";
        break;
      case "auth/user-not-found":
        message =
          "No pudimos encontrar la cuenta de usuario asociada con la dirección de correo electrónico o el número de teléfono.";
        break;
      case "auth/phone-number-already-exists":
        message =
          "El número de teléfono ya está siendo utilizado por un usuario existente.";
        break;
      case "auth/invalid-phone-number":
        message = "¡El número de teléfono no es un número de teléfono válido!";
        break;
      case "auth/invalid-email  ":
        message =
          "¡La dirección de correo electrónico no es una dirección de correo electrónico válida!";
        break;
      case "auth/cannot-delete-own-user-account":
        message = "No puede eliminar su propia cuenta de usuario.";
        break;
      default:
        message = "¡Ups! Algo salió mal. Vuelva a intentarlo más tarde.";
        break;
    }

    return message;
  }
}
