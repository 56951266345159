import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { GeolocationComponent } from './geolocation/geolocation.component';
import { ChatComponent } from './layout/components/chat/chat.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';


const routes: Routes = [
  {path: '', loadChildren: () => import ('./layout/layout.module').then(m => m.LayoutModule)},
  {path: 'signin', component: SignInComponent},
  {path: 'signup', component: SignUpComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'emailConfirmation',component: EmailConfirmationComponent},
  {path: 'geolocation', component: GeolocationComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
