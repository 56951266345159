// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  demo: "saas", // other possible options are creative and modern
  apiKey: "AIzaSyCql7vhWcfR6WrAaRdb0Sv5ajagN9en_lc",
  firebaseConfig: {
    apiKey: "AIzaSyCql7vhWcfR6WrAaRdb0Sv5ajagN9en_lc",
    authDomain: "towndelivery-c9355.firebaseapp.com",
    databaseURL: "https://towndelivery-c9355.firebaseio.com",
    projectId: "towndelivery-c9355",
    storageBucket: "towndelivery-c9355.appspot.com",
    messagingSenderId: "105859506649",
    appId: "1:105859506649:web:600df718f1e297ddd8b9c8",
    measurementId: "G-PXJMJCR4V1",
  },
  animal: "🐊",
  shipping: 1400,
  wompi_transation_url: "https://sandbox.wompi.co/v1/transactions/",
  redirect_url: "http://localhost:4200/payment",
  public_payment_key: "pub_test_SoqcyoKuvd5uw8Tco5tVyQimR9SS98IO",
  private_payment_key: "prv_test_GarMaAzTdYwRQqZHVs5EkbfAgVpc5i1B",
  private_prod_integrity: "prod_integrity_GPMmseR0KAivndp1BedEs80uANOW7dPt",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
